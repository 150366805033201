import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Table } from '@mantine/core'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { chipStore } from './OverView'
import { useChipData } from './useChipData'

export const OverViewTable = memo<ReactProps>(function OverViewTable() {
  const theme = useThemeStore(s => s.theme)

  const row1Color = theme === 'light' ? '#f5f5f5' : '#121212'
  const row2Color = theme === 'light' ? '#aaaaaa' : '#1a1a1a'
  const tdColor = theme === 'light' ? '#222222' : '#ffffff'

  const staticState = useSnapshot(chipStore)
  const twseData = useChipData().twse?.slice(-30).reverse() //新至舊(上往下排序)
  const tpexData = useChipData().tpex?.slice(-30).reverse() //新至舊(上往下排序)
  const txfData = useChipData().txf?.slice(-30).reverse() //新至舊(上往下排序)
  const optData = useChipData().opt?.slice(-30).reverse() //新至舊(上往下排序)

  const styleds = {
    tr: styled.tr`
      display: grid;
      grid-template-columns: repeat(5, 20%);
    `,
    td: styled.td<{ value: number }>`
      color: ${option => tdFill(option.value)};
    `,
    scrollbar: css`
      width: 100%;
      height: 100%;
      ${flex.v.default};
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 6px;
        background-color: ${row1Color};
      }
      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 5px;
        background-color: ${row2Color};
      }
    `,
  }

  const tableData = () => {
    if (staticState.stata === 'twse') {
      return twseData
    }
    if (staticState.stata === 'tpex') {
      return tpexData
    }
    if (staticState.stata === 'txf') {
      return txfData
    }
    if (staticState.stata === 'opt') {
      return optData
    }
  }

  const tdFill = (value: number) => {
    if (value > 0) {
      return '#ff0000'
    } else if (value < 0) {
      return '#00aa00'
    } else return tdColor
  }

  const rows = tableData()?.map(dutum => (
    <styleds.tr key={dutum.date}>
      <styleds.td value={NaN}>{dutum.date}</styleds.td>
      <styleds.td value={dutum.foreigner}>{dutum.foreigner}</styleds.td>
      <styleds.td value={dutum.dealer}>{dutum.dealer}</styleds.td>
      <styleds.td value={dutum.trust}>{dutum.trust}</styleds.td>
      <styleds.td value={dutum.total}>{dutum.total}</styleds.td>
    </styleds.tr>
  ))

  return (
    <Table
      striped
      highlightOnHover
      css={css`
        // ${styleds.scrollbar};
        // &.mantine-Table-root {
        //   height: 800px;
        // }
      `}
    >
      <thead>
        <tr
          css={css`
            display: grid;
            grid-template-columns: repeat(5, 20%);
          `}
        >
          <th>日期</th>
          <th>外資</th>
          <th>自營商(合計)</th>
          <th>投信</th>
          <th>加總</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  )
})
